@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");

.app-header {
  background-color: #ffffff;
  box-shadow: 0px 10px 40px #0037631a;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}

.app-header img {
  width: 215px;
  height: auto;
}

.contenedor {
  background: transparent linear-gradient(180deg, #ffffff 0%, #f5f9fa 100%) 0%
    0% no-repeat padding-box;
  height: 100vh;
  text-align: center;
}

.contenedor-paises {
  height: calc(100vh - 10vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #003763;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0px;
}
p,
a {
  font-family: "Poppins", sans-serif;
  margin: 0;
  text-decoration: none;
}

.contenedor-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 50px;
  padding-bottom: 6%;
}

.card {
  background: #fff;
  box-shadow: 0px 16px 40px #0037631a;
  border-radius: 20px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border: 2px solid #fff;
}

.card img {
  width: 60%;
}

.contenedor-grid a:hover .card {
  border: 2px solid #0099b7;
  cursor: pointer;
}

.contenedor-grid a:hover p {
  text-decoration: underline;
}

a {
  color: #003763;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .app-header img {
    width: 185px;
  }
}
